import React from 'react'
import styled from 'styled-components'
import media from 'utils/media-queries'

import GlobalWrapper from 'components/global-wrapper'
import Header from 'components/header'
import ContentWrapper from 'components/contentWrapper'
import Footer from 'components/footer'
import LargeImage from 'components/largeImage'
import FullSizeImage from 'components/fullSizeImage'
import Seo from 'components/seo'

import PalettteCoverImage from 'img/articles/palettte-app/palettteapp.jpg'
import PalettteImage1 from 'img/articles/palettte-app/palettte1.jpg'
import PalettteImage2 from 'img/articles/palettte-app/palettte2.jpg'
import PalettteImage3 from 'img/articles/palettte-app/palettte3.jpg'
import PalettteImage4 from 'img/articles/palettte-app/palettte4.jpg'

const Section = styled.section`
  margin: calc(32px + 2vw) 0;
`

const PalettteApp = () => {
  return (
    <GlobalWrapper>
      <Seo title="Palettte App" />
      <Header />
      <Section>
        <ContentWrapper>
          <h1>Palettte App</h1>
          <h2>The advanced color palette editor</h2>
          <p>
            Palettte App is a free design tool that helps you to build,
            analalyze and finetune color palettes.
          </p>
          <p>
            Import palettes via JSON or start from scratch, tweak them and
            export them again to JSON, css or via plain text into other design
            tools.
          </p>
          <p>I designed and developed this tool with react and gatsby.</p>
        </ContentWrapper>
        <FullSizeImage src={PalettteCoverImage} />
        <ContentWrapper>
          <h3>Why yet another color palette tool?</h3>
          <p>
            Most existing apps generate new color schemes based on different
            geometrical concepts such as complementary colors or colors that are
            laid out in a triangle or other shapes in a given color space.
          </p>
          <p>
            The resulting color schemes might be suitable for logos or classic
            graphic design, but they rarely work for UI Design, where you
            usually need to work with a few base colors (often defined by brand
            guidelines) and a whole range of different variations and shades.
          </p>

          <p>
            Steve Schoger does a good job of describing how to build such a
            color palette:
            <br />
            <a href="https://refactoringui.com/previews/building-your-color-palette/">
              Building your color palette
            </a>
          </p>
          <p>
            Another great article on this topic is by Eric Kennedy:
            <br />
            <a href="https://learnui.design/blog/color-in-ui-design-a-practical-framework.html">
              Color in UI Design - a practical Framework
            </a>
          </p>
          <p>
            What Steve doesn't describe exactly is how to find those colors:
            <i>
              "...you just need to fill in the gaps in between", "Trust your
              eyes, not the numbers."
            </i>
          </p>

          <p>This is where Palettte App comes in:</p>
          <ul>
            <li>
              Create <b>smooth color schemes</b> that flow from one color to
              another
            </li>
            <li>
              <b>Fine-tune the hue, saturation value gradients</b> of your
              palette and of each color swatch individually
            </li>
            <li>
              Import, analyze and <b>edit existing color schemes</b>, find
              errors or automatically find the most similar colors from a new
              palette to a list of legacy colors from your codebase
            </li>
          </ul>
          <br />
          <h3>Building a new color palette</h3>
          <p>
            To create a new color palette, click on the plus icon in the left
            sidebar and delete all sample colors by hitting the backspace key.
            You can now add your base color by clicking "add swatch" or by
            hitting the space bar and entering the HEX value in the right
            sidebar.
          </p>
          <p>
            As you add more swatches you can see that three curves form: one for
            the hue, one for the saturation and one for the value (=brightness)
            of the colors.
          </p>
          <LargeImage src={PalettteImage2} />
          <p>
            A basic palette for UI Design consists of 8-10 shades where the hue
            stays the same for all shades, the value goes up and the saturation
            goes down <b>smoothly</b> as the colors get lighter.
          </p>
          <br />
          <h3>Analyzing and editing existing color palettes</h3>
          <p>
            If you already have a color palette and you want to see if it can be
            optimized, you can import the colors one at a time or write the
            palette down in JSON format (example can be seen when you click on
            import) and batch import your palette.
          </p>
          <p>
            Here is the palette Steve Schoger uses in his example in Refactoring
            UI:
          </p>
          <LargeImage src={PalettteImage3} />
          <p>
            As you can see there is a little dip in the value envelope curve and
            the saturation curve starts out round and then suddenly drops off
            linearly. Nothing too serious but it's clear that Steve chose his
            colors by "trusting his eyes" and not his numbers ;).
          </p>
          <p>
            You can edit the lines by dragging the handles around or by
            selecting two handles and clicking on "interpolate" (only linear
            interpolation is supported at the moment).
          </p>
          <LargeImage src={PalettteImage4} />
          <p>
            When you are happy with the result, simply export your new colors
            again.
          </p>
          <br />
          <h3>Mapping old colors to new ones</h3>
          <p>
            Often you will find yourself in a situation where you have built a
            wonderful new color palette, but your legacy code does not reflect
            the beauty of it, because a lot of different shades and colors have
            accumulated over time.
          </p>
          <p>
            With Palettte App, you can import these (legacy) colors and
            automatically find the most similar colors from your new palettes
            automatically. You will also immediatly see immediatly when colors
            are too far off, and you need to add more colors to your palettes.
          </p>
          <LargeImage src={PalettteImage1} />
          <p>
            It can also be useful if you want to add shades to your palette and
            need to rename all existing colors.
          </p>
          <br />
          <h3>Feedback welcome</h3>
          <p>
            If you have any ideas on how to make this tool better, please let me
            know via <a href="https://twitter.com/gabdorf">Twitter</a>.
          </p>
          <p>
            <i>Update:</i>{' '}
            <a href="https://twitter.com/steveschoger/status/1097890956873998336">
              Steve Schoger
            </a>{' '}
            and{' '}
            <a href="https://twitter.com/erikdkennedy/status/1095012612230660096">
              Eric Kennedy
            </a>{' '}
            are now both using Palettte App.
          </p>
        </ContentWrapper>
      </Section>
      <Footer />
    </GlobalWrapper>
  )
}

export default PalettteApp
