import React from 'react'
import styled from 'styled-components'
import { v } from '../utils/v-size'

const Image = styled.img`
  max-height: 550px;
  display: block;
  margin: ${v(32, 56)} auto;
  max-width: 100%;
  border-radius: 8px;
`

const LargeImage = props => {
  return <Image src={props.src} alt={props.alt} />
}

export default LargeImage
